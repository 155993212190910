import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=3c12a1d6&scoped=true&lang=pug&"
import script from "./Select.vue?vue&type=script&lang=ts&"
export * from "./Select.vue?vue&type=script&lang=ts&"
import style0 from "./Select.vue?vue&type=style&index=0&id=3c12a1d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c12a1d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})
